import React, { useEffect } from "react";
import "./OrderPlacedScreen.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetCart } from "../features/counter/cartSlice";

function OrderPlacedScreen() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    fnfItemAdded,
    itemsAdded,
    uniqOrderID,
    total,
    orderTime,
    userInfo,
    deliveryAddress,
    fnfItemsTotal,
  } = state || {};

  useEffect(() => {
    if (state === null || state === undefined || state === "undefined") {
      navigate("/");
    }
  }, [navigate, state]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetCart()); // clearing out the cart
  }, [dispatch]);

  const orderId = uniqOrderID;

  return (
    <section className="main-section">
      <div className="mywrapper flex-col">
        <h2 className="success-title">Your Order has been placed!</h2>
        <div className="horizontal-line"></div>
        <div className="receiptHeaader">
          <h2 className="order-id">
            Order ID: <u>{orderId}</u>
          </h2>
          <h2 className="timeOfOrder">{orderTime}</h2>
        </div>
        <div className="horizontal-line"></div>
        <div className="order-summary">
          <h3 className="summaryTitle">Order Summary:</h3>
          <div className="MycartItemsContainer">
            {itemsAdded?.map((item, index) => {
              return <CartItem key={index} item={item} />;
            })}
            {fnfItemAdded?.map((item, index) => {
              return <FnFItem key={index} item={item} />;
            })}
          </div>

          <div className="billsummary">
            <div className="col">
              <div className="row">
                <p className="biggerText">HUH products</p>{" "}
                <p className="biggerText">${total + fnfItemsTotal}</p>
              </div>
              <div className="row">
                <p>Shipping</p> <p>$0</p>
              </div>
              <div className="row">
                <p>GST</p> <p>$0</p>
              </div>
              <div className="row">
                <p>PST</p> <p>$0</p>
              </div>
              <div className="horizontal-line"></div>
              <div className="row">
                <p>Sub Total:</p> <p>${total + fnfItemsTotal}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line"></div>

        <h3 className="summaryTitle">Thank you from huh team! </h3>

        <br></br>
        <br></br>
        <br></br>

        <div className="order-summary">
          <div className="billsummary">
            <div className="col">
              <div className="row"></div>
              <div className="horizontal-line"></div>
              <div className="row">
                <p>Total:</p> <p>${total + fnfItemsTotal}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="user-info">
          <h3 className="info-title">User Information</h3>
          {/* <p>Name: {userInfo.name}</p> */}
          <p>Email: {userInfo?.email}</p>
          {/* <p>Phone: {userInfo.phone}</p> */}
        </div>
        <div className="order-summary">
          <h3 className="info-title">Delivery Address</h3>

          <div className="billsummary">
            <div className="col">
              <div className="row">
                {deliveryAddress && (
                  <p>
                    {`${deliveryAddress.line1}${" "}
                  ${
                    deliveryAddress.line2 !== null ? deliveryAddress.line2 : ""
                  }${" "}
                  ${deliveryAddress.city}${" "}
                  ${deliveryAddress.country}${" "}
                  ${deliveryAddress.postal_code}`}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div id="disclaimer" className="order-summary">
          <div className="billsummary">
            <div className="col">
              <div className="horizontal-line"></div>
              <div className="row">
                <p className="subHeading">
                  Collection of Information: a. When you place an order for a
                  t-shirt on our website, we collect certain personal
                  information such as your name, email address & shipping
                  address. This information is necessary to process and fulfill
                  your order.
                  <br></br>
                  Please refer to the email address below to inquire about your
                  purchase: <u>howuniversehowls@gmail.com</u>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const FnFItem = (props) => {
  const { name, price, imagesCollection } = props.item;
  return (
    <div className="itemDisplay" style={{ height: "inherit" }}>
      <div className="itemImg">
        <img
          alt={imagesCollection.items[0].title}
          src={imagesCollection.items[0].url}
        ></img>
      </div>
      <div className="itemInfo">
        <h2 className="productName">{name}</h2>
        <h2 className="productPrice">${price}</h2>
        {/* <h2 className="productSize">size: {size.charAt(0).toUpperCase()}</h2> */}

        {/* <div className="selectQuantity">
          <button
            className="selectBtns removeFnfBtn"
            onClick={() => {
              dispatch(removeFnFItem({ itemToRemove: props.item }));
            }}
          >
            <h2>Remove</h2>
          </button>
        </div> */}
      </div>
    </div>
  );
};

const CartItem = (props) => {
  const { priceInCad, images, quantity, id, size, name } = props.item;
  return (
    <div className="myitemDisplay">
      <div className="myitemImg">
        <img src={require(`../shared/${images[0]}`)} alt={id}></img>
      </div>
      <div className="itemInfo">
        <h2 className="productName">{name}</h2>
        <h2 className="productPrice">${priceInCad}</h2>
        <h2 className="productSize">Size: {size.charAt(0).toUpperCase()}</h2>
        <h2 className="productSize">Quantiy: {quantity}</h2>
      </div>
    </div>
  );
};

export default OrderPlacedScreen;
