import React from 'react';
import Slider from 'react-slick';
// import imageData from '../shared/itemdata';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css"

const Carousel = () => {
    // const imageDTF = imageData.find(img => img.id === 'dtf');
    var settings = {
      dots: true,
      infinite: true,
      speed:500,
      slidesToShow: 3,
      slidesToScroll: 2
    };

  
    return (
        <div className="carousel">
      <Slider {...settings}>
        {/* Add your slide components here */}
        <div>
          <h3>1</h3>
            {/* <img src={require(`../shared/${imageDTF.images[0]}`)} alt="dtfimg" className="car-img" /> */}
        </div>
        <div>
          <h3>2</h3>
            {/* <img src={require(`../shared/${imageDTF.images[1]}`)} alt="dtfimg" className="car-img" /> */}
        </div>
        <div>
          <h3>3</h3>
            {/* <img src={require(`../shared/${imageDTF.images[2]}`)} alt="dtfimg" className="car-img" /> */}
        </div>
        <div>
          <h3>4</h3>
            {/* <img src={require(`../shared/${imageDTF.images[3]}`)} alt="dtfimg" className="car-img" /> */}
        </div>
        <div>
          <h3>5</h3>
            {/* <img src={require(`../shared/${imageDTF.images[4]}`)} alt="dtfimg" className="car-img" /> */}
        </div>
      </Slider>
       </div>
    );
  };
  
  export default Carousel;