import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const spaceId = 'tk1klmouwvnw';
const accessToken = 'WJ62oQDlLWWFHPPeqOz1t1rt3vT9h0RqKCOW7vF3Fog';
const environment = 'master';

const httpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${environment}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default client;
