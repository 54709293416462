import React, { useState, useEffect } from "react";
import { init } from "@paralleldrive/cuid2";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./Stripe.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// eslint-disable-next-line
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);
const stripePromise = loadStripe('pk_live_51NAHGXC7rGiHlc1EvAIDCP1hYZg5EEnkbMnuXFXskTHPn2bA14CbDAjB3YpS6GQTFubQABcayFu2yZkHjPmCr57Z00Tuur16cY');

function Stripe() {
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState();
  const { total, itemsAdded, fnfItemsTotal, fnfItemAdded } = useSelector(
    (state) => state.cart
  );


  useEffect(() => {
    const host = 'https://finallaunchserver-1-0-0-uillvhmwdq-uc.a.run.app';
    console.log("REACT_APP_HOST:", host);

    const postPaymentIntent = async () => {
      try {
        // const response = await fetch(`${host}/create-payment-intent`, {
        const response = await fetch(`https://finallaunchserver-1-0-0-uillvhmwdq-uc.a.run.app/create-payment-intent`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            checkoutTotal: total + fnfItemsTotal,
            itemsAdded: itemsAdded,
            fnfItemAdded: fnfItemAdded,
          }),
        });
        const data = await response.json();
        setClientSecret(data.clientSecret);
        setPaymentIntentId(data.id);
      } catch (err) {
        console.error(err.message);
      }
    };
    postPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: clientSecret,
    appearance: appearance,
  };

  return (
    <section className="stripeSection">
      <div>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              clientSecret={clientSecret}
              paymentIntentId={paymentIntentId}
            />
          </Elements>
        )}
      </div>
    </section>
  );
}

const CheckoutForm = (props) => {
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState({});

  const stripe = useStripe();
  const elements = useElements();

  const { total, fnfItemsTotal, itemsAdded, fnfItemAdded } = useSelector(
    (state) => state.cart
  );
  // eslint-disable-next-line

  const navigate = useNavigate();

  const createId = init({
    random: Math.random,
    length: 7,
    fingerprint: "a-custom-host-fingerprint",
  });
  const uniqItemID = createId();
  const uniqUserID = createId();
  const uniqOrderID = createId();

  //state values to send: rename to payloads
  const now = new Date();

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  const timestamp = now.toLocaleString("en-US", options);

  const payloads = {
    fnfItemAdded: fnfItemAdded,
    itemsAdded: itemsAdded,
    uniqOrderID: uniqOrderID,
    uniqItemID: uniqItemID,
    uniqUserID: uniqUserID,
    total: total,
    orderTime: timestamp,
    userInfo: {
      email: email,
    },
    fnfItemsTotal: fnfItemsTotal,
    deliveryAddress: address,
  };

  const handleSuccess = () => {
    // TO MAKE OTHER DB CALLS:
    axios
      // .post(`${process.env.REACT_APP_HOST}/update-contentful-availability`, {
      .post(`https://finallaunchserver-1-0-0-uillvhmwdq-uc.a.run.app/update-contentful-availability`, {
        fnfItemAdded: fnfItemAdded,
      })
      .then((response) => {
        console.log('after @/update-cms api call  => ', response.data);
      })
      .catch((error) => {
        console.error("Error here:", error);
      });

    // console.log('@handleSuccess')
    navigate("/orderplaced", { state: payloads });
  };

  return (
    <section className="checkoutSection">
      <div className="wrapper">
        <form
          className="mainForm"
          onSubmit={async (e) => {
            e.preventDefault();

            if (!stripe || !elements) {
              return;
            }
            const { error: submitError } = await elements.submit();
            if (submitError) {
              console.error("error thrown here xdew");
              return;
            }

            try {
              const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                  return_url: "https://google.com",
                },
                redirect: "if_required",
              });

              if (error) {
                console.error(error);
                // handleError();
              } else if (
                paymentIntent &&
                paymentIntent.status === "succeeded"
              ) {
                console.log("Payment succeeded");
                handleSuccess();
              } else {
                console.log("Payment failed");
                // handleOther();
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <div className="stripeElements flexCol">
            <h3 className="elementTitle">Email:</h3>
            <LinkAuthenticationElement
              className="emailElement singleStripeElement"
              onChange={(event) => {
                setEmail(event.value.email);
              }}
            />
          </div>

          <div className="stripeElements">
            <h3 className="elementTitle">Shipping Address:</h3>
            <AddressElement
              className="singleStripeElement"
              options={{ mode: "shipping" }}
              onChange={(event) => {
                if (event.complete) {
                  setAddress(event.value.address);
                }
              }}
            />
          </div>

          <div className="stripeElements">
            <h3 className="elementTitle">Payment</h3>
            <PaymentElement
              // Optional prop for prefilling customer information
              options={{
                defaultValues: {
                  billingDetails: {
                    name: "John Doe",
                    phone: "888-888-8888",
                  },
                },
              }}
            />
          </div>

          <div className="col finalCbox">
            <div className="row">
              <p>Shipping</p> <p>$0</p>
            </div>
            <div className="horizontal-line"></div>
            <div className="row">
              <p>Sub Total:</p> <p>${total + fnfItemsTotal}</p>
            </div>
            <div className="row biggerText">
              <h2 className="">
                Click the submit button below to confirm payment:
              </h2>
              <button className="btn" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Stripe;
