import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import client from "../apollo-client";
import { useDispatch } from "react-redux";
import { setCartIsOpen, addFnFItem } from "../features/counter/cartSlice";

import "../components/LandingPage.css";
import "./FriendsAndFamily.css";

const GET_PRODUCTS = gql`
  query {
    ecommerceProductCollection {
      items {
        sys {
          id
        }
        name
        price
        isAvailable
        description {
          json
        }
        imagesCollection(limit: 10) {
          items {
            title
            url
          }
        }
      }
    }
  }
`;

function FriendsAndFamily() {
  const { state } = useLocation();
  const { isAllowedAccess } = state || false;
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_PRODUCTS, { client });

  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    if (isAllowedAccess === false || isAllowedAccess === undefined) {
      navigate("/");
    }
  }, [isAllowedAccess, navigate]);
  useEffect(() => {
    if (data && data.ecommerceProductCollection) {
      setItemData(data.ecommerceProductCollection.items);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error bruh</p>;

  return (
    <section className="fnfSection">
      <div className="fnfCol">
        {itemData &&
          itemData.map((item, index) => <ItemBox key={index} item={item} />)}
      </div>
    </section>
  );
}

export default FriendsAndFamily;

const ItemBox = ({ item }) => {
  const { name, price, isAvailable, description, imagesCollection } = item;

  const storageKey = `isAdded_${item.name}`;
  const [isAdded, setIsAdded] = useState(() => {
    const value = localStorage.getItem(storageKey);
    return value ? value === "true" : false; // Assume not added if key does not exist
  });

  useEffect(() => {
    const handleStorageUpdate = (event) => {
      if (event.detail === storageKey) {
        const value = localStorage.getItem(storageKey);
        setIsAdded(value ? value === "true" : false);
      }
    };

    // Listen for the custom event
    window.addEventListener("storageUpdate", handleStorageUpdate);

    return () => {
      // Cleanup listener
      window.removeEventListener("storageUpdate", handleStorageUpdate);
    };
  }, [storageKey]);

  return (
    <div className="itemBox" key={description}>
      <div className="itemImgFnf">
        <div className="imgContainer">
          <img
            className="imgTag"
            alt={imagesCollection.items[0].title}
            src={imagesCollection.items[0].url}
          ></img>
        </div>
      </div>
      <div className="itemTitle">
        <p>{name}</p>
      </div>
      <p className="fnfitemPrice">${price} CAD</p>
      <div className="itemDes">
        <p>{description.json?.content[0]?.content[0]?.value}</p>
      </div>
      <BuyItemButton
        buttonText="GET"
        item={item}
        isAdded={isAdded}
        setIsAdded={setIsAdded}
        isButtonDisabled={isAvailable ? false : true}
      />
    </div>
  );
};

const BuyItemButton = ({
  buttonText = "sample",
  item = {},
  isAdded,
  setIsAdded,
  isButtonDisabled = false,
}) => {
  const dispatch = useDispatch();
  const storageKey = `isAdded_${item.name}`;

  const handleAddToCart = (item) => {
    dispatch(setCartIsOpen(true));
    dispatch(addFnFItem({ itemToAdd: item }));
    localStorage.setItem(storageKey, "true");
    setIsAdded(true);
  };
  if (isButtonDisabled) {
    return (
      <div className="buyButton soldOut">
        <p>SOLD OUT</p>
      </div>
    );
  }

  if (isAdded) {
    return (
      <div className="alreadyAdded">
        <p>Added to Cart!</p>
      </div>
    );
  }

  return (
    <button className="buyButton" onClick={() => handleAddToCart(item)}>
      <p>{buttonText}</p>
    </button>
  );
};
